define('m01new/controllers/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  Ember['default'].deprecate = function () {};

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Controller.extend({

    wuerth: false,
    swg: false,
    swghandel: false,
    bauder: true,
    bauderMaster: false,
    reisser: false,

    environment: "m01_bauder", // "offline" = offline Version, "m01" = default würth,  "m01dev" = würth dev, "m01_bauder" = bauder, "m01_bauderdev" = bauder dev, "m01swg" = swg,  "m01swgdev" = swg dev,

    loggedIn: false,

    // https://localhost:4200/?user_name=horst&user_sessionid=0.575940869444&spracheinput=DE&spracheoutput=DE&kennungland=DE&katalog=DE&loadsCode=EC+1+NAD+(D)&loadsNA=DE&timberCode=EC+5+NAD+(D)&timberNA=DE
    // https://localhost:4200/?user_name=horst&user_sessionid=0.273331503937&spracheinput=DE&spracheoutput=DE&kennungland=DE&katalog=DE&loadsCode=EC+1+NAD+(D)&loadsNA=DE&timberCode=EC+5+NAD+(D)&timberNA=DE

    version: "##version##",

    initialized: false,
    calculationid: "",
    debug: true,
    xml: false,

    authenticatingUser: false,

    dachgeometrieInvalid: true,
    daemmungInvalid: true,
    unterkonstruktionInvalid: true,

    projektdatenInvalid: false,
    bauteileInvalid: true,
    anschlussgeometrieInvalid: false,
    lasteinwirkungInvalid: true,
    verbindungsmittelInvalid: true,

    invalidForVerbindungsmittel: true,

    schraubenInfo: "",
    schraubenCAD: "",
    produktkatalog: "https://www.timberconnect.de/produktinfo/wuerth/katalog/katalog_de.pdf",
    eta: "https://www.timberconnect.de/produktinfo/wuerth/eta/eta-110190_de.pdf",
    unsaved: true,

    server: "",
    pfad: "",

    urlLoginPage: "",

    i18n: inject.service(),
    current: computed.readOnly('i18n.locale'),

    bemessungsvorschrift: "EC1/EC5 (DE)",

    zeigeAuswahlLasteinstellung: true,
    lasteinwirkungsReiter: 'lasteinwirkung',
    currentPath: 'projektdaten',

    username: '',
    sessionid: '',

    cC: "DE",

    si: "DE",
    so: "DE",
    kl: "DE",
    kat: "DE",
    lc: "EC 1 NAD (DE)",
    ln: "DE",
    tc: "EC 5 NAD (DE)",
    tn: "DE",

    languages: {
      "DE": "deutsch",
      "DK": "dansk",
      "EN": "englisch",
      "FR": "franzoesisch",
      "IT": "italienisch",
      "NL": "nederlands",
      "PL": "polnisch",
      "ES": "spanisch",
      "FI": "suomi",
      "SE": "schwedisch",
      "PT": "portugiesich",
      "CZ": "tschechisch"
    },

    countries: {
      "CL": "chile",
      "DK": "daenemark",
      "DE": "deutschland",
      "EN": "england",
      "UK": "england",
      "FI": "finnland",
      "FR": "frankreich",
      "IE": "irland",
      "NL": "niederlande",
      "IT": "italien",
      "LU": "luxemburg",
      "HR": "kroatien",
      "NO": "norwegen",
      "AT": "oesterreich",
      "PL": "polen",
      "RO": "rumaenien",
      "SE": "schweden",
      "CH": "schweiz",
      "SK": "slowakei",
      "SI": "slowenien",
      "ES": "spanien",
      "CZ": "tschechien",
      "HU": "ungarn",
      "PT": "portugal",
      "BE": "belgien"

    },

    selectedLanguage: "deutsch",
    selectedCountry: "deutschland",

    init: function init() {
      var self = this;
      this._super();

      var username = self.getParameterByName('user_name');
      var sessionid = self.getParameterByName('user_sessionid');

      self.setCountryVars();

      self.set('username', username);
      self.set('sessionid', sessionid);

      self.set('authenticatingUser', true);

      // console.log('1: Authentifizieren von '+username+' ::: mit '+sessionid);

      // var application = self.controllerFor('application');
      // var applicationdata = self.get('model').application.objectAt(0);
      //
      var applicationdataJSON = JSON.stringify(self.get('model'));

      // console.log('/////');
      // console.log(self.get('model'));
      // console.log(applicationdataJSON);

      var environment = externalConfig.environments[self.environment];
      var server = externalConfig[environment].server;
      var pfad = externalConfig[environment].pfad;

      var environment = externalConfig.environments[self.get('environment')];
      var url = externalConfig[environment].uriAuthentifizierung;

      $.ajax({
        type: "GET",
        url: server + 'bekommeKonfiguration',

        error: function error(_error) {
          console.log(' error');
          console.log(_error);
        }
      }).done(function (response) {

        console.log(response);

        var configdata = self.get('model').config.objectAt(0);
        configdata.set('lastannahmenEC1ServiceURL', response.lastannahmenEC1ServiceURL);
      });

      $.ajax({
        type: "POST",
        url: url,
        data: {
          username: username,
          sessionid: sessionid
        }
      }).done(function (res) {
        // console.log(res);
        var authenticationResult = res.split(";");
        // console.log(authenticationResult);
        var auth = parseInt(authenticationResult[0]);
        var level = parseInt(authenticationResult[1]);
        // console.log(authenticationResult);
        if (auth == -1) {
          console.log("login unsuccessful.");
          self.set('authenticatingUser', false);
        } else if (auth === 0) {
          self.set('loggedIn', true);
          self.set('authenticatingUser', false);
          if (level === 0) {
            self.set('bauderMaster', false);
          } else if (level >= 1) {
            self.set('bauderMaster', true);
          }
          var applicationdata = self.get('model').application.objectAt(0);
          applicationdata.set('userName', username);
          console.log("login successful!");
        }

        var applicationdata = self.get('model').application.objectAt(0);

        applicationdata.set('spracheinput', self.get('si'));
        self.send('setSpracheInput', self.get('si'));

        applicationdata.set('spracheoutput', self.get('so'));
        applicationdata.set('kennungland', self.get('kl'));
        // applicationdata.set('countrycode', self.get('kl'));
        applicationdata.set('katalog', self.get('kat'));
        applicationdata.set('loadsCode', self.get('lc'));
        applicationdata.set('loadsNA', self.get('ln'));
        applicationdata.set('timberCode', self.get('tc'));
        applicationdata.set('timberNA', self.get('tn'));

        self.set('selectedCountry', self.get('i18n').t(self.countries[self.get('kl')]));

        self.setLasteinwirkungsreiter(self.get('ln'));

        // $.ajax({
        //   type: "POST",
        //   url: server + "m01/DE/initialisiereGUI/" +self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),

        //   data: {
        //
        //   }
        // }).done(function(data, statusText, xhr) {

        self.controllerFor('supercontroller').logaufruf("initialisiereGUI", 200);

        //   console.log('initialisiereGUI data: ');
        //   console.log(data);
        //
        // })
      });

      self.store.createRecord('bauteile', {});
      self.store.createRecord('lasteinwirkung', {});
      self.store.createRecord('anschlussgeometrie', {});
      self.store.createRecord('projektdaten', {});

      self.transitionToRoute('projektdaten');
      var environment = externalConfig.environments[self.environment];

      console.log('self.environment: ' + self.environment);
      console.log('environment: ' + environment);
      self.set('server', externalConfig[environment].server);
      self.set('pfad', externalConfig[environment].pfad);
      self.set('urlLoginPage', externalConfig[environment].uriLoginpage);

      // console.log('server: '+self.get('server'));
      // console.log('pfad: '+self.get('pfad'));
      // console.log('urlLoginPage: '+self.get('urlLoginPage'));

      if (self.bauder) {
        self.produktkatalog = "";
        self.eta = "https://timberconnect.de/dokumente/bauder/eta-12-0197_swg_timber_screws.pdf";

        // self.set('server', externalConfig.m01_bauderdev.server);
        // self.set('pfad', externalConfig.m01_bauderdev.pfad);
      } else {}
        // self.set('server', externalConfig.m01.server);

        // console.log(externalTranslations);

      self.get('i18n').addTranslations('de', externalTranslations.de);
      self.get('i18n').addTranslations('cz', externalTranslations.cz);
      self.get('i18n').addTranslations('dk', externalTranslations.dk);
      self.get('i18n').addTranslations('ee', externalTranslations.ee);
      self.get('i18n').addTranslations('en', externalTranslations.en);
      self.get('i18n').addTranslations('es', externalTranslations.es);
      self.get('i18n').addTranslations('fi', externalTranslations.fi);
      self.get('i18n').addTranslations('fr', externalTranslations.fr);
      self.get('i18n').addTranslations('gr', externalTranslations.gr);
      self.get('i18n').addTranslations('hr', externalTranslations.hr);
      self.get('i18n').addTranslations('hu', externalTranslations.hu);
      self.get('i18n').addTranslations('it', externalTranslations.it);
      self.get('i18n').addTranslations('lt', externalTranslations.lt);
      self.get('i18n').addTranslations('lv', externalTranslations.lv);
      self.get('i18n').addTranslations('nl', externalTranslations.nl);
      self.get('i18n').addTranslations('no', externalTranslations.no);
      self.get('i18n').addTranslations('pl', externalTranslations.pl);
      self.get('i18n').addTranslations('pt', externalTranslations.pt);
      self.get('i18n').addTranslations('ro', externalTranslations.ro);
      self.get('i18n').addTranslations('se', externalTranslations.se);
      self.get('i18n').addTranslations('si', externalTranslations.si);
      self.get('i18n').addTranslations('sk', externalTranslations.sk);
    },

    // getConfiguration: function(){

    //   console.log('getConfiguration')
    //   var self = this;

    //   $.ajax({
    //     type: "GET",
    //     url: ' https://timberconnect.com/swg_m01_dev/v1.1/bekommeKonfiguration/',

    //     error: function (error) {
    //       console.log('error')
    //       console.log(error)
    //     }
    //   }).done(function(response) {
    //     console.log('bekommeKonfiguration')
    //     console.log(response)
    //     console.log(response.lastannahmenEC1ServiceURL)

    //     var configdata = self.get('model').config.objectAt(0);
    //     configdata.set('lastannahmenEC1ServiceURL', response.lastannahmenEC1ServiceURL);

    //   })

    // },

    getParameterByName: function getParameterByName(name, url) {

      if (!url) {
        url = window.location.href;
      }
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    setCountryVars: function setCountryVars() {

      var self = this;

      var si = self.getParameterByName('spracheinput');
      var so = self.getParameterByName('spracheoutput');
      var kl = self.getParameterByName('kennungland');
      var kat = self.getParameterByName('katalog');
      var lc = self.getParameterByName('loadsCode');
      var ln = self.getParameterByName('loadsNA');
      var tc = self.getParameterByName('timberCode');
      var tn = self.getParameterByName('timberNA');

      if (window.location.href.indexOf("timberCode") === -1) {

        console.log("timberCode === -1");

        self.set('si', "DE");
        self.set('so', "DE");
        self.set('kl', "DE");
        self.set('kat', "DE");
        // self.set('lc', "EC 1 NAD (DE)");
        self.set('lc', "EC1");
        self.set('ln', "DE");
        // self.set('tc', "EC 5 NAD (DE)");
        self.set('tc', "EC5");
        self.set('tn', "DE");
        self.set('bemessungsvorschrift', "EC1 (DE) / EC5 (DE)");
      } else {

        console.log("timberCode !== -1");

        self.set('si', si);
        self.set('so', so);
        self.set('kl', kl);
        self.set('kat', kat);
        self.set('lc', lc);
        self.set('ln', ln);
        self.set('tc', tc);
        self.set('tn', tn);
        self.set('bemessungsvorschrift', "EC1 (" + self.getZweistelligesLKZ(lc) + ") / EC5 (" + self.getZweistelligesLKZ(tc) + ")");
      }
    },

    getZweistelligesLKZ: function getZweistelligesLKZ(str) {
      var lkz = "";
      if (str.slice(10, str.length - 1).length === 2) {
        lkz = str.slice(10, str.length - 1);
      } else {
        if (str.slice(10, str.length - 1) === "D") {
          lkz = "DE";
        } else if (str.slice(10, str.length - 1) === "A") {
          lkz = "AT";
        } else if (str.slice(10, str.length - 1) === "I") {
          lkz = "IT";
        } else if (str.slice(10, str.length - 1) === "F") {
          lkz = "FR";
        }
      }
      return lkz;
    },

    // ?user_name=&user_sessionid=xxx&spracheinput=FR&spracheoutput=FR&kennungland=CH&katalog=DE&loadsCode=EC%201%20NAD%20(CH)&loadsNA=CH&timberCode=EC%205%20NAD%20(D)&timberNA=DE&foo=0.9948646273596267

    resetApplication: function resetApplication() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      self.transitionToRoute('projektdaten');
    },

    watchValidations: (function () {

      var self = this;

      // console.log(self.get('dachgeometrieInvalid'));
      // console.log(self.get('unterkonstruktionInvalid'));
      // console.log(self.get('daemmungInvalid'));
      // console.log(self.get('lasteinwirkungInvalid'));

      if (self.get('dachgeometrieInvalid') || self.get('unterkonstruktionInvalid') || self.get('daemmungInvalid') || self.get('lasteinwirkungInvalid')) {
        // console.log("hier dürfte der reiter nicht freigeschaltet sein.");
        self.set('invalidForVerbindungsmittel', true);
      } else {
        // console.log("hier müsste es klappen.");
        self.set('invalidForVerbindungsmittel', false);
      }
    }).observes('dachgeometrieInvalid', 'unterkonstruktionInvalid', 'daemmungInvalid', 'lasteinwirkungInvalid'),

    setLasteinwirkungsreiter: function setLasteinwirkungsreiter(bemessungsvorschrift) {
      var self = this;
      var projektdaten = this.controllerFor('projektdaten');

      if (bemessungsvorschrift === "DE") {
        projektdaten.set('werteAusPlzService', true);
      } else {
        projektdaten.set('werteAusPlzService', false);
      }

      var x3d = this.get('model').x3ddefault.objectAt(0);
      if (bemessungsvorschrift === "DE") {
        this.set('lasteinwirkungsReiter', 'lasteinwirkung');
        // projektdaten.set('werteAusPlzService', true);
      } else if (bemessungsvorschrift === "AT") {
          this.set('lasteinwirkungsReiter', 'lasteinwirkungat');
          // projektdaten.set('werteAusPlzService', false);
        } else if (bemessungsvorschrift === "FR") {
            this.set('lasteinwirkungsReiter', 'lasteinwirkungfr');
            // projektdaten.set('werteAusPlzService', false);
          } else if (bemessungsvorschrift === "CH") {
              this.set('lasteinwirkungsReiter', 'lasteinwirkungch');
              // projektdaten.set('werteAusPlzService', false);
            } else if (bemessungsvorschrift === "CZ") {
                this.set('lasteinwirkungsReiter', 'lasteinwirkungcz');
                // projektdaten.set('werteAusPlzService', false);
              } else if (bemessungsvorschrift === "SK") {
                  this.set('lasteinwirkungsReiter', 'lasteinwirkungsk');
                  // projektdaten.set('werteAusPlzService', false);
                } else if (bemessungsvorschrift === "HU") {
                    this.set('lasteinwirkungsReiter', 'lasteinwirkunghu');
                    // projektdaten.set('werteAusPlzService', false);
                  } else if (bemessungsvorschrift === "RO") {
                      this.set('lasteinwirkungsReiter', 'lasteinwirkungro');
                      // projektdaten.set('werteAusPlzService', false);
                    } else if (bemessungsvorschrift === "SI") {
                        this.set('lasteinwirkungsReiter', 'lasteinwirkungsi');
                        // projektdaten.set('werteAusPlzService', false);
                      } else if (bemessungsvorschrift === "IT") {
                          this.set('lasteinwirkungsReiter', 'lasteinwirkungit');
                          // projektdaten.set('werteAusPlzService', false);
                        } else if (bemessungsvorschrift === "UK") {
                            this.set('lasteinwirkungsReiter', 'lasteinwirkunguk');
                            // projektdaten.set('werteAusPlzService', false);
                          } else if (bemessungsvorschrift === "PL") {
                              this.set('lasteinwirkungsReiter', 'lasteinwirkungpl');
                              // projektdaten.set('werteAusPlzService', false);
                            } else if (bemessungsvorschrift === "NL") {
                                this.set('lasteinwirkungsReiter', 'lasteinwirkungnl');
                                // projektdaten.set('werteAusPlzService', false);
                              } else if (bemessungsvorschrift === "BE") {
                                  this.set('lasteinwirkungsReiter', 'lasteinwirkungbe');
                                  // projektdaten.set('werteAusPlzService', false);
                                } else if (bemessungsvorschrift === "LU") {
                                    this.set('lasteinwirkungsReiter', 'lasteinwirkunglu');
                                    // projektdaten.set('werteAusPlzService', false);
                                  } else if (bemessungsvorschrift === "NO") {
                                      this.set('lasteinwirkungsReiter', 'lasteinwirkungno');
                                      // projektdaten.set('werteAusPlzService', false);
                                    } else if (bemessungsvorschrift === "DK") {
                                        this.set('lasteinwirkungsReiter', 'lasteinwirkungdk');
                                        // projektdaten.set('werteAusPlzService', false);
                                      } else if (bemessungsvorschrift === "HR") {
                                          this.set('lasteinwirkungsReiter', 'lasteinwirkunghr');
                                          // projektdaten.set('werteAusPlzService', false);
                                        } else if (bemessungsvorschrift === "SE") {
                                            this.set('lasteinwirkungsReiter', 'lasteinwirkungse');
                                            // projektdaten.set('werteAusPlzService', false);
                                          } else {
                                              this.set('lasteinwirkungsReiter', 'lasteinwirkung');
                                              // projektdaten.set('werteAusPlzService', false);
                                            }

      // this.controllerFor(this.get('lasteinwirkungsReiter')).send('validation', 0.0, {target:{name:"v_b0"}});

      this.controllerFor(this.get('lasteinwirkungsReiter')).setDefaultValues();
      this.controllerFor(this.get('lasteinwirkungsReiter')).send('validation', -1, { target: { name: "foobar" } });
    },

    actions: {

      setSpracheInput: function setSpracheInput(spracheInput) {
        var self = this;

        console.log(" ");
        console.log("##### setSpracheInput #####");
        console.log(" ");
        console.log("spracheInput: " + spracheInput);
        console.log(" ");
        console.log("################ ");
        console.log(" ");

        this.get('i18n').set('locale', spracheInput.toLowerCase());
        var applicationdata = this.get('model').application.objectAt(0);
        applicationdata.set('spracheinput', spracheInput);
        applicationdata.set('spracheoutput', spracheInput);
        self.send('setCountryCode', spracheInput);

        self.set('selectedLanguage', self.get('i18n').t(self.languages[spracheInput]));

        if (spracheInput == "DE") {
          self.set('hilfelink', 'https://timberconnect.de/hilfe/wuerth/html/de/m01___aufdachdammung_vorgehangte_fassade.html?ms=AAAA&st=MA%3D%3D&sct=MA%3D%3D&mw=MzAw#'); // Würth
        } else {
            self.set('hilfelink', 'https://timberconnect.de/hilfe/wuerth/html/en/m01___insulation_of_roof_facade.html?ms=AAAA&st=MA%3D%3D&sct=MA%3D%3D&mw=MzAw'); // Würth
          }

        this.controllerFor('impressum').set('selectedLanguage', spracheInput);

        this.controllerFor('dachgeometrie').setSelectFieldsContent();
        this.controllerFor('unterkonstruktion').setSelectFieldsContent();
        this.controllerFor('daemmung').setSelectFieldsContent();
        this.controllerFor(this.get('lasteinwirkungsReiter')).setSelectFieldsContent();

        self.resetApplication();
      },

      setCountryCode: function setCountryCode(countryCode) {

        countryCode = countryCode === "EN" ? "UK" : countryCode;

        var self = this;

        self.set('cC', countryCode);

        var applicationdata = this.get('model').application.objectAt(0);
        // applicationdata.set('countrycode', countryCode);
        applicationdata.set('kennungland', countryCode);

        var firm = applicationdata.get('firm');

        var projektdaten = this.controllerFor('projektdaten');

        if (countryCode === "DE") {
          projektdaten.set('plzservice', true);
        } else {
          projektdaten.set('plzservice', false);
        }

        var downloadcenter = this.controllerFor('downloadcenter');

        // var prodkaturl = "https://www.onlinebemessung.de/webapps/webapp_wuerth/produktinfo/" + countryCode.toLowerCase() + "/Catalog.pdf";
        // var etaurl = "https://www.onlinebemessung.de/webapps/webapp_wuerth/produktinfo/" + countryCode.toLowerCase() + "/ETA-110190.pdf";

        var cC = countryCode;
        var eta = "120196";

        if (typeof linksDB.firm.findBy('id', firm) === "undefined") {
          firm = "SWG";
        }

        if (typeof linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC) === "undefined") {
          cC = "EN";
        }

        if (firm === "WUERTH") {
          eta = "110190";
        } else if (firm === "BAUDER") {
          eta = "120197";
        }

        var prodkaturl = "";
        var etaurl = "";

        if (self.get('environment') === "offline") {
          prodkaturl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", "katalog").offline;
          etaurl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", eta).offline;
        } else {
          prodkaturl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", "katalog").file;
          etaurl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", eta).file;
        }

        this.set('eta', etaurl);
        this.set('produktkatalog', prodkaturl);
        downloadcenter.set('eta', etaurl);
        downloadcenter.set('produktkatalog', prodkaturl);

        self.set('selectedCountry', self.get('i18n').t(self.countries[countryCode]));

        self.resetApplication();
      },

      setETA: function setETA(eta) {
        var applicationdata = this.get('model').application.objectAt(0);
        // applicationdata.set('countrycode', countryCode);

        var etaurl = "assets/public/docs/eta_" + eta.toLowerCase() + ".pdf";

        this.set('eta', etaurl);
      },

      setBemessungsvorschrift: function setBemessungsvorschrift(bemessungsvorschrift) {
        var self = this;
        var applicationdata = this.get('model').application.objectAt(0);
        var projektdaten = this.controllerFor('projektdaten');
        var dachgeometrie = this.controllerFor('dachgeometrie');
        var ln = bemessungsvorschrift;
        var tn = bemessungsvorschrift;

        self.setLasteinwirkungsreiter(bemessungsvorschrift);

        if (this.get('currentPath').indexOf("lasteinwirkung") === -1) {
          this.transitionToRoute(this.get('lasteinwirkungsReiter'));
        }

        if (bemessungsvorschrift === "DE" || bemessungsvorschrift === "AT" || bemessungsvorschrift === "SE") {
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', bemessungsvorschrift);
        } else if (bemessungsvorschrift === "IT") {
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', bemessungsvorschrift);
        } else if (bemessungsvorschrift === "FR") {
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', bemessungsvorschrift);
        } else if (bemessungsvorschrift === "CH") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "CZ") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "SK") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "SI") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "HU") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "RO") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "UK") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "PL") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "NL") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "BE") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "LU") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "NO") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "HR") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "SE") {
          tn = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (DE)");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', "DE");
        } else if (bemessungsvorschrift === "FI") {
          ln = "DE";
          // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
          // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
          applicationdata.set('loadsNA', "DE");
          applicationdata.set('timberNA', bemessungsvorschrift);
        } else if (bemessungsvorschrift === "DK") {
          // applicationdata.set('loadsCode', "EC 1 NAD ("+bemessungsvorschrift+")");
          // applicationdata.set('timberCode', "EC 5 NAD ("+bemessungsvorschrift+")");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', bemessungsvorschrift);
        }

        applicationdata.set('loadsCode', "EC1");
        applicationdata.set('timberCode', "EC5");

        this.set('bemessungsvorschrift', "EC1 (" + ln + ") / EC5 (" + tn + ")");

        self.resetApplication();
        // dachgeometrie.set('konstruktion', 0);
        dachgeometrie.setKonstruktion();
        dachgeometrie.setSelectFieldsContent();
      },

      downloadcenter: function downloadcenter(spracheInput) {
        this.transitionToRoute('downloadcenter');
      },

      verbindungsmittel: function verbindungsmittel(event) {
        var lasteinwirkung = this.controllerFor(this.get('lasteinwirkungsReiter'));
        var applicationdata = this.get('model').application.objectAt(0);

        if (!applicationdata.get('treeLoaded')) {
          console.log("jetzt müsste der Tree neu geladen werden.");
          lasteinwirkung.send('proceedInput');
        } else {
          console.log("jetzt müsste der Tree NICHT neu geladen werden.");
        }
      },

      ergebnis: function ergebnis(event) {
        var verbindungsmittel = this.controllerFor('verbindungsmittel');

        verbindungsmittel.send('proceedInput');
      },

      daemmungladen: function daemmungladen(event) {
        var daemmung = this.controllerFor('daemmung');

        daemmung.send('getDaemmstoffbaum');
      },

      newAction: function newAction(event) {
        console.log(window.location.href);

        var self = this;

        var protocol = window.location.protocol;
        var host = window.location.host;

        var applicationdata = this.get('model').application.objectAt(0);
        var si = applicationdata.get('spracheinput');
        var so = applicationdata.get('spracheoutput');
        var kl = applicationdata.get('kennungland');
        var kat = applicationdata.get('katalog');
        var lc = applicationdata.get('loadsCode');
        var ln = applicationdata.get('loadsNA');
        var tc = applicationdata.get('timberCode');
        var tn = applicationdata.get('timberNA');
        var bv = self.get('bemessungsvorschrift');

        window.location.href = protocol + "//" + host + "/?user_name=" + self.get('username') + "&user_sessionid=" + self.get('sessionid') + "&spracheinput=" + si + "&spracheoutput=" + so + "&kennungland=" + kl + "&katalog=" + kat + "&loadsCode=" + lc + "&loadsNA=" + ln + "&timberCode=" + tc + "&timberNA=" + tn + "&bemessungsvorschrift=" + bv;
      },

      contactAction: function contactAction(event) {

        tools.validationsToTooltips();

        var self = this;

        self.transitionToRoute('contact');
      },

      produktkatalogAnzeigen: function produktkatalogAnzeigen(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      etaAnzeigen: function etaAnzeigen(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      setCameraActiv: function setCameraActiv(camera) {
        var lastCamera = this.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', this.get('model').x3ddefault.objectAt(0).get('lastViewpoint'));

        this.get('model').x3ddefault.objectAt(0).set('lastViewpoint', camera);

        var activeCamera = this.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', camera);

        lastCamera.get('cameraProperties').objectAt(0).set('setBind', false);
        activeCamera.get('cameraProperties').objectAt(0).set('setBind', true);

        // resetViewpoint() zu finden in /bower_components/viewpointmanagement/vpmanagements.js
        // setzt die viewpoints zurück

        this.zentriertObjekt();

        var x, y, z;
        var x3d = this.get('model').x3ddefault.objectAt(0);
        var bauteile = x3d.get('bauteile');

        this.get('model').x3ddefault.objectAt(0).set('viewpointHelper', !this.get('model').x3ddefault.objectAt(0).get('viewpointHelper'));
      },

      setDrahtgittermodel: function setDrahtgittermodel() {
        this.get('model').x3ddefault.objectAt(0).set('istGitterModell', !this.get('model').x3ddefault.objectAt(0).get('istGitterModell'));
      },

      saveAction: function saveAction() {

        console.log('speichern()');

        var self = this;

        self.transitionToRoute('downloadcenter');
        var dc = self.controllerFor('downloadcenter');
        dc.set('savingInProgress', true);

        var application = self.controllerFor('application');
        var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
        applicationdata.set('treeLoaded', false);
        applicationdata.set('initialized', false);
        applicationdata.set('pdfErstellt', false);

        // application.set('verbindungsmittelInvalid', true);

        self.set('displayErrors', false);

        //var applicationdata = application.model.objectAt(0);

        //universal
        var applicationdata = application.get('model').application.objectAt(0);
        var applicationdataJSON = JSON.stringify(applicationdata);

        var projektdatendata = application.get('model').projektdaten.objectAt(0);
        var projektdatendataJSON = JSON.stringify(projektdatendata);

        //m06

        var bauteiledata = application.get('model').bauteile.objectAt(0);
        var bauteiledataJSON = JSON.stringify(bauteiledata);

        var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
        var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);

        //m01

        var dachgeometriedata = application.get('model').dachgeometrie.objectAt(0);
        var dachgeometriedataJSON = JSON.stringify(dachgeometriedata);

        var unterkonstruktiondata = application.get('model').unterkonstruktion.objectAt(0);
        var unterkonstruktiondataJSON = JSON.stringify(unterkonstruktiondata);

        var daemmungdata = application.get('model').daemmung.objectAt(0);
        var daemmungdataJSON = JSON.stringify(daemmungdata);

        //universal

        var lasteinwirkung = self.controllerFor(self.get('lasteinwirkungsReiter'));
        var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
        var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

        var uebergabedaten = $.extend(true, JSON.parse(applicationdataJSON), JSON.parse(projektdatendataJSON), JSON.parse(dachgeometriedataJSON), JSON.parse(unterkonstruktiondataJSON), JSON.parse(daemmungdataJSON), JSON.parse(lasteinwirkungdataJSON));

        // console.log('projektdatendataJSON');
        // console.log(projektdatendataJSON);
        // console.log(JSON.parse(projektdatendataJSON));
        // console.log(uebergabedaten);

        //Lastannahmen/Parameter_Strukt.vb

        var timberelementgl = self.controllerFor('supercontroller').getTimberelementGl();
        var timberelementsp = self.controllerFor('supercontroller').getTimberelementSp();

        // var timberelementgl = {
        //   "Querschnittswerte": {
        //     "t_Fastener": daemmungdata.get('glHoehe'),
        //     "QuerschnittsID": daemmungdata.get('QuerschnittsID'),
        //     "b": daemmungdata.get('glBreite'),
        //     "h": daemmungdata.get('glHoehe')
        //   },
        //   "vorgebohrt": false,
        // 	"MatKey": daemmungdata.get('MatKeyTimberElementGl'),
        // 	"FKLKey": daemmungdata.get('FKLKeyTimberElementGl')
        //
        // };
        //
        // var timberelementsp = {
        //   "Querschnittswerte": {
        //     "t_Fastener": unterkonstruktiondata.get('TimberElementSp_h'),
        //     "QuerschnittsID": unterkonstruktiondata.get('QuerschnittsID'),
        //     "b": unterkonstruktiondata.get('TimberElementSp_b'),
        //     "h": unterkonstruktiondata.get('TimberElementSp_h')
        //   },
        //   "vorgebohrt": false,
        // 	"MatKey": unterkonstruktiondata.get('MatKeyTimberElementSp'),
        // 	"FKLKey": unterkonstruktiondata.get('FKLKeyTimberElementSp')
        // };

        if (self.debug) {
          console.log('slzarray: ');
          console.log(lasteinwirkung.slzarray);
          console.log('slz_id: ');
          console.log(lasteinwirkungdata.get('SLZ_ID'));
          console.log('SLZ_Text: ' + lasteinwirkungdata.get('SLZ_Text'));
        }

        uebergabedaten.TimberElementGl = timberelementgl;
        uebergabedaten.TimberElementSp = timberelementsp;
        uebergabedaten.schnee = self.controllerFor('supercontroller').getSchneelastParameter();
        uebergabedaten.wind = self.controllerFor('supercontroller').getWindlastParameter();
        uebergabedaten.gebaeudegeometrie = self.controllerFor('supercontroller').getGebaeudegeometrie();

        // console.log('uebergabedaten - 1');
        // console.log(JSON.stringify(uebergabedaten));

        var JSONdata = JSON.stringify(uebergabedaten);

        //var JSONdata = "{" + dachgeometriedataJSONcut + "," + unterkonstruktiondataJSONcut + "," + daemmungdataJSONcut + "," + lasteinwirkungdataJSONcut + "}";
        //var JSONdata = "{" + bauteileString + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";

        // var JSONdata = "{" + projektdatendataJSONcut + "," + bauteiledataJSONcut + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";
        if (self.debug) {
          // console.log('applicationdataJSON');
          // console.log(applicationdataJSON);
          // console.log('projektdatendataJSON');
          // console.log(projektdatendataJSON);
          // console.log('uebergabedaten - 2');
          // console.log(JSONdata);
        }

        var parasToSave = JSON.parse(JSONdata);
        var dataToSave = JSON.stringify(auxiliary.save([parasToSave]));

        console.log('dataToSave');
        console.log(dataToSave);
        console.log('applicationdata._data');
        console.log(applicationdata._data);

        var initialized = applicationdata.get('initialized');

        var server = application.get('server');
        var pfad = application.get('pfad');
        // var pfad = "D:/swgengineering/bin/";

        console.log('server: ' + server);
        console.log('pfad: ' + pfad);

        if (!initialized) {

          $.ajax({
            type: "POST",
            url: server + "initialisiereBerechnung/",
            // url: "https://136.243.4.143/berechnungsservice/m01/DE/initialisiereBerechnung/",
            data: {
              kennung: applicationdataJSON,
              // projektdaten: projektdatendataJSON,
              paras: JSONdata,
              modul: "m01",
              pfad: pfad
            }
          }).done(function (data, statusText, xhr) {

            self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

            var cid = data;

            console.log('cid: ' + cid);

            applicationdata.set('Calculation_ID', cid);
            applicationdata.set('initialized', true);
            dc.set('cid', data);

            $.ajax({
              type: "POST",
              url: server + "speichereBerechnung/" + cid + "/",
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {

              self.controllerFor('supercontroller').logaufruf("speichereBerechnung", xhr.status);

              dc.set('saveFile', data);
              dc.set('savingInProgress', false);
              dc.set('saved', true);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          });
        } else {

            var cid = applicationdata.get('Calculation_ID');

            $.ajax({
              type: "POST",
              url: server + "speichereBerechnung/" + cid + "/",
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {

              self.controllerFor('supercontroller').logaufruf("speichereBerechnung", xhr.status);

              dc.set('saveFile', data);
              dc.set('savingInProgress', false);
              dc.set('saved', true);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          }
        self.set('unsaved', false);
      },

      /**
       * This action is called when a file has been loaded.
       *
       * @method ACTION: fileLoaded
       */
      fileLoaded: function fileLoaded(file) {

        var self = this;

        if (self.xml) {
          var test = self.parseXml(file.data);
          console.log(xml2json(test, ""));
        } else {
          var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);

          var projektdaten = self.controllerFor('projektdaten');
          var dachgeometrie = self.controllerFor('dachgeometrie');
          var unterkonstruktion = self.controllerFor('unterkonstruktion');
          var daemmung = self.controllerFor('daemmung');

          var values = JSON.parse(file.data);

          self.send('setSpracheInput', values.spracheinput);

          applicationdata.set('katalog', values.katalog);

          // applicationdata.set('countrycode', values.countrycode );
          applicationdata.set('kennungland', values.kennungland);
          applicationdata.set('userID', values.userID);
          // applicationdata.set('userName', values.userName );
          applicationdata.set('loadsCode', values.loadsCode);
          applicationdata.set('timberCode', values.timberCode);
          applicationdata.set('loadsNA', values.loadsNA);
          applicationdata.set('timberNA', values.timberNA);

          self.setLasteinwirkungsreiter(values.loadsNA);
          var lasteinwirkung = self.controllerFor(self.get('lasteinwirkungsReiter'));

          // console.log("loadsNA: "+values.loadsNA+" :: "+self.getZweistelligesLKZ(values.loadsNA));
          // console.log("timberNA: "+values.timberNA+" :: "+self.getZweistelligesLKZ(values.timberNA));
          // console.log("lasteinwirkungsReiter: "+self.get('lasteinwirkungsReiter'));
          // console.log("istFassade: "+lasteinwirkung.get('istFassade'));
          // console.log(" ");

          self.set('bemessungsvorschrift', "EC1 (" + values.loadsNA + ") / EC5 (" + values.timberNA + ")");

          projektdaten.setValues(JSON.parse(file.data));
          dachgeometrie.setValues(JSON.parse(file.data));
          unterkonstruktion.setValues(JSON.parse(file.data));
          daemmung.setValues(JSON.parse(file.data));
          lasteinwirkung.setValues(JSON.parse(file.data));
          // bauteile.setValues(JSON.parse(file.data)); // wegen x3d aktuell noch auskommentiert.
          // anschlussgeometrie.setValues(JSON.parse(file.data)); // wegen x3d aktuell noch auskommentiert.

          console.log("istFassade: " + lasteinwirkung.get('istFassade'));
          console.log(" ");
          self.zentriertObjekt();
        }
      },

      toggleForm: function toggleForm() {
        $('.clearfix').toggleClass('formZu');
        $('#formToggler').toggleClass('open');

        setTimeout(function () {
          var x3dGrafik = document.getElementById("threedee");
          var x3d = document.getElementById("x3d");
          var canvas = document.getElementsByTagName("canvas");
          var x3druntime = document.getElementById('x3d').runtime;

          canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          // x3d.setAttribute('height', Number(x3druntime.getWidth()) * 3 / 5);
          x3d.setAttribute('height', Number($(window).height()) - 300);
        }, 400);
      }

    },

    miniertX3D: function miniertX3D() {
      $('.clearfix').removeClass('formZu');
      $('#formToggler').removeClass('open');

      setTimeout(function () {
        var x3dGrafik = document.getElementById("threedee");
        var x3d = document.getElementById("x3d");

        x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        x3d.setAttribute('height', Number($(window).height()) - 300);
      }, 400);
    },

    parseXml: function parseXml(xml) {

      var dom = null;
      if (window.DOMParser) {
        try {
          dom = new DOMParser().parseFromString(xml, "text/xml");
        } catch (e) {
          dom = null;
        }
      } else if (window.ActiveXObject) {
        try {
          dom = new ActiveXObject('Microsoft.XMLDOM');
          dom.async = false;
          if (!dom.loadXML(xml)) // parse error ..

            window.alert(dom.parseError.reason + dom.parseError.srcText);
        } catch (e) {
          dom = null;
        }
      } else alert("cannot parse xml string!");
      return dom;
    },

    x3dRotation: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var rotation;
      if (x3d.get('istFassade') === false && x3d.get('istDachkonstruktion') && !x3d.get('bauteile').findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') && !x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
        rotation = '0 0 1 ' + x3d.get('vWinkel') * Math.PI / 180;
      } else if (x3d.get('istFassade') === true && x3d.get('istDachkonstruktion')) {
        rotation = '0 0 1 ' + x3d.get('hWinkel') * Math.PI / 180;
      } else {
        rotation = '0 0 0 0';
      }
      return rotation;
    }).property('model.x3ddefault.firstObject.istFassade', 'model.x3ddefault.firstObject.istDachkonstruktion', 'model.x3ddefault.firstObject.vWinkel', 'model.x3ddefault.firstObject.viewpointHelper'),

    istDachkonstruktion: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      return x3d.get('istDachkonstruktion');
    }).property('model.x3ddefault.firstObject.istDachkonstruktion'),

    dachausfuehrung: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      return x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('ausfuehrung');
    }).property('model.x3ddefault.firstObject.istDachkonstruktion', 'model.x3ddefault.firstObject.dachgeometrieGeandert'),

    istSatteldach: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var value = false;
      if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'satteldach') {
        value = true;
      }
      return value;
    }).property('model.x3ddefault.firstObject.dachgeometrieGeandert'),

    istPultdach: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var value = false;
      if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'pultdach') {
        value = true;
      }
      return value;
    }).property('model.x3ddefault.firstObject.dachgeometrieGeandert'),

    istWalmdach: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var value = false;
      if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'walmdach') {
        value = true;
      }
      return value;
    }).property('model.x3ddefault.firstObject.dachgeometrieGeandert'),

    schalung: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var value = false;
      if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') === 'holz') {
        value = true;
      }
      return value;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    traufekonstruktion: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var value = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('traufkonstruktion');
      return value;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    daemmung2: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var value = false;

      if (Number(x3d.get('bauteile').findBy('id', 'daemmung2').get('boxsizes').objectAt(0).get('y') > 0)) {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    grundlatte2: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      return !x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('grundlatteMaterial');
    }).property('model.x3ddefault.firstObject.transformHelper'),

    sparren2: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var value = true;
      if (x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') === 'beton') {
        value = false;
      }
      return value;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    bauderID: (function () {
      var value = true;
      var self = this;
      if (self.get('bauder') && !self.get('bauderMaster')) {
        value = false;
      }
      return value;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    bauderTraufkonstruktion: (function () {
      var value = false;
      var self = this;

      var x3d = this.get('model').x3ddefault.objectAt(0);

      if (self.get('bauder') && x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('schalung') === 'beton') {
        value = true;
      }
      return value;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    zentriertObjekt: function zentriertObjekt() {
      var x,
          y,
          z,
          distanz = 10;
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('istDachkonstruktion')) {
        x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('laengeUnterkonstruktion') / 10;
        y = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('hoeheUnterkonstruktion') / 10;
        z = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('sparrenabstand') / 10;

        var arr = [x, y, z];
        arr.sort(function (a, b) {
          return b - a;
        });

        var d = 0;
        var val = Number(arr[0]);

        if (bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind') === true) {
          d = Math.sqrt(Math.pow(val, 2) + Math.pow(val * 0.4, 2) + Math.pow(val, 2));
        } else {
          d = arr[0];
        }

        x3d.set('aktuelleDistance', d);

        resetViewpointUK(d);
      } else {

        x = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('gebaeudelaenge') / 10;
        y = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('firsthoehe') / 10;
        z = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('gebaeudebreite') / 10;
        var liste = [x, y, z];
        liste.sort(function (a, b) {
          return b - a;
        });
        distanz = liste[0] * 10;

        // x3d.set('aktuelleDistance', distanz )

        resetViewpointDK(distanz);
      }
    },

    abstaendeAnzeigen: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      return x3d.get('abstaendeAnzeigen');
    }).property('model.x3ddefault.firstObject.abstaendeAnzeigen'),

    ansichtLinks: (function () {
      var value = false;
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }
      return value;
    }).property('model.x3ddefault.firstObject.lastViewpoint'),

    ansichtFront: (function () {
      var value = false;
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true && name !== 'lager1' || bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') && name === 'lager1') {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.lastViewpoint'),

    ansichtOben: (function () {
      var value = false;
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.lastViewpoint'),

    istInEntwicklung: (function () {
      var value = false;
      if (this.get('environment').search('dev') !== -1) {
        value = true;
      }
      return value;
    }).property(''),

    bauderlogo: (function () {
      var self = this;
      var value = "https://timberconnect.de/images/BauderLogo_";

      // vorhandene Logos: CZ, FR, HR, HU, IT, NL, PL, RO, SE, SI, SK, UK

      if (self.get('cC') === "CZ" || self.get('cC') === "FR" || self.get('cC') === "HR" || self.get('cC') === "HU" || self.get('cC') === "IT" || self.get('cC') === "NL" || self.get('cC') === "PL" || self.get('cC') === "RO" || self.get('cC') === "SO" || self.get('cC') === "SI" || self.get('cC') === "SK" || self.get('cC') === "UK") {
        value = value + self.get('cC') + '.png';
      } else {
        value = value + 'DE.png';
      }

      return value;
    }).property('cC'),

    etaListe: (function () {
      var self = this;

      var firm = self.get('model').application.objectAt(0).get('firm').toLowerCase();
      var cC = self.get('model').application.objectAt(0).get('kennungland').toLowerCase();

      var liste = Ember['default'].Set.create();

      if (cC === "uk") {
        cC = "en";
      } else if (cC === 'ch') {
        cC = self.get('model').application.objectAt(0).get('spracheinput').toLowerCase();
      }

      // http://localhost:4200/?user_name=&user_sessionid=xxx&spracheinput=FR&spracheoutput=FR&kennungland=CH&katalog=DE&loadsCode=EC%201%20NAD%20(CH)&loadsNA=CH&timberCode=EC%205%20NAD%20(DE)&timberNA=DE&foo=0.8809340330217658
      // http://localhost:4200/?user_name=&user_sessionid=xxx&spracheinput=IT&spracheoutput=IT&kennungland=CH&katalog=DE&loadsCode=EC%201%20NAD%20(CH)&loadsNA=CH&timberCode=EC%205%20NAD%20(DE)&timberNA=DE&foo=0.7349075194145123

      // console.log(firm);
      // console.log(cC);
      // console.log(etaConfig);
      // console.log(etaConfig[firm]);
      // console.log(etaConfig[firm][cC]);
      // console.log(etaConfig[firm][cC]["files"]);

      // Einträge für Bauder Files fehlen
      if (firm !== 'bauder') {

        // console.log("Object.values");

        // console.log(Object.values(etaConfig[firm][cC]["files"]["ETAs"]));

        Object.values(etaConfig[firm][cC]["files"]["ETAs"]).forEach(function (item, i) {

          console.log(item);

          var eintrag = { name: item.name, beschreibung: item.beschreibung, link: "https://timberconnect.de" + item.pfad.replace("..", "") + item.dateiname, m0601: item.m0601 };
          liste.add(eintrag);
        });
      }

      return liste.toArray();
    }).property('cC'),

    urlProduktkatalog: (function () {
      var self = this;

      var firm = self.get('model').application.objectAt(0).get('firm').toLowerCase();
      var cC = self.get('model').application.objectAt(0).get('kennungland').toLowerCase();

      if (cC === "uk") {
        cC = "en";
      } else if (cC === 'ch') {
        cC = self.get('model').application.objectAt(0).get('spracheinput').toLowerCase();
      }

      var item = etaConfig[firm][cC]["files"]["Catalogs"]["0"];

      var katalogurl = null;

      if (self.get('swghandel') === true) {
        katalogurl = item.pfad;
      } else {
        katalogurl = "https://timberconnect.de" + item.pfad.replace("..", "") + item.dateiname;
      }

      var downloadcenter = this.controllerFor('downloadcenter');
      downloadcenter.set('produktkatalog', katalogurl);
      downloadcenter.set('katalogFehlt', false);

      return katalogurl;
    }).property('cC'),

    contactURL: (function () {
      var self = this;
      var applicationdata = this.get('model').application.objectAt(0);
      return self.get('handelLinks')[applicationdata.get('spracheinput')].Kontakt;
    }).property('selectedLanguage'),

    imprintURL: (function () {
      var self = this;
      var applicationdata = this.get('model').application.objectAt(0);
      return self.get('handelLinks')[applicationdata.get('spracheinput')].Impressum;
    }).property('selectedLanguage'),

    handelLinks: {
      'DE': {
        'Impressum': "https://swg.de/impressum/",
        'Kontakt': "https://swg.de/kontakt/"
      },
      'EN': {
        'Impressum': "https://swg.de/en/disclaimer/",
        'Kontakt': "https://swg.de/en/contact/"
      },
      'FR': {
        'Impressum': "https://swg.de/fr/mentions-legales/",
        'Kontakt': "https://swg.de/fr/contact/"
      }
    },

    fileExtension: (function () {
      var self = this;

      if (self.get('wuerth')) return ".wu01";else if (self.get('swg')) return ".sw01";else if (self.get('swghandel')) return ".sw01";else if (self.get('bauder')) return ".ba01";else if (self.get('reisser')) return ".re01";else return 'xxx';
    }).property('wuerth', 'swg', 'swghandel', 'bauder', 'reisser')

  });

});